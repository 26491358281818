import { useEffect } from 'react';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';

import Intercom, {update as intercomUpdate} from '@intercom/messenger-js-sdk';

import { useAppStore, Membership, AppState } from 'wrapper';
// helpers
// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';

const App = () => {
    const user = useAppStore((state: AppState) => state.user);

    useEffect(() => {
        AOS.init();
    }, []); 


    useEffect(() => {
        if (user?.email !== null && user?.memberships) { 
            const companies = user.memberships.map((m: Membership) => {
                const company: {id: string, name: string, plan?: string|null, industry?: string} = {
                    id: m.account.id,
                    name: m.account.name
                }

                if (m.current){
                    company.plan = user.subscription?.plan
                    if(user.profile?.industry){
                        company.industry = user.profile.industry
                    }
                    if(user.profile?.company){
                        company.name = user.profile?.company
                    }
                }

                return company
            });
            // const membership = user.memberships.filter((m: Membership) => m.current)[0]
            const update = { 
                email: user.email,
                // user_id: user.id,
                name: user.name,
                companies: companies,
                // created_at: user.created_at,
                // app_id: 'ca19z8i5',
            }
            intercomUpdate(update as any);
        }
    }, [user]);

    Intercom({
        app_id: 'ca19z8i5',
    });

    // configureFakeBackend();
    return <Routes />;
};

export default App;
